// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import map from 'lodash/map'

import { graphql } from 'gatsby'
import orderBy from 'lodash/orderBy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import '../components/grid-without-animation/style.less'
import '../components/grid-resource-renderer/style.less'

import getRandomArbitraryInt from '../methods/getRandomArbitraryInt'
import formattedDate from '../methods/formatted-date'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

const pageSchema = {
  title: 'Aura Stories',
  slug: '/aura-stories',
  abstract:
    'Our weekly video series showcasing the members of the Aura Network and their experiences on the platform. Produced in collaboration with Alessandra Silver.',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Aura Stories', slug: '/aura-stories' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query AuraStoriesPageQuery {
    allAuraStoriesJson {
      nodes {
        publishedTimestamp
        routeSlug
        shortAbstract
        title
        subTitle
        quote
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 900
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ data, mediaState, ...restProps }) => {
  const {
    allAuraStoriesJson: { nodes },
  } = data
  const orderedNodes = orderBy(nodes, ['publishedTimestamp'], ['desc'])

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...restProps}>
      <div className="container">
        <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
        <h1>Aura Stories</h1>
        <p className="hero">
          Our video series showcasing the members of the Aura Network and their
          experiences on the platform. Produced in collaboration with Alessandra
          Silver.
        </p>
        <div className="standard-grid without-animation">
          {map(orderedNodes, (node) => (
            <Link className="grid-resource" to={node.routeSlug}>
              <div className="stuff">
                <h3 className="title" style={{ marginBottom: 0 }}>
                  {node.title}
                </h3>
                <p style={{ marginBottom: '0.55rem' }}>
                  {node.subTitle}, {formattedDate(node.publishedTimestamp)}
                </p>
              </div>
              <GatsbyImage
                style={{ minHeight: '6rem' }}
                className="cover as-paragraph"
                image={getImage(node.cover)}
                durationFadeIn={getRandomArbitraryInt(150, 450)}
              />
              <p className="short-abstract" style={{ marginBottom: 0 }}>
                {node.quote}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // dispatch => ({
  //   updateWebsiteTheme(payload) {
  //     dispatch(updateWebsiteTheme(payload))
  //   },
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
